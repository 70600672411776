import styled from 'styled-components'

import colors from '../../colors'
import media from './mediaqueries'

export const FormWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  padding: 0 50px;
  width: 750px;
  text-align: center;

  ${media.smallDesktop`
    left: 50%;
    transform: translateX(-50%);
  `}

  ${media.mobile`
    width: 100%;
    left: 0;
    transform: unset;
    margin-top: 30px;
    padding: 0 32px;
  `}
`

export const FormInnerWrapper = styled.div`
  position: relative;
  width: 650px;
  max-width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 3px 6px ${colors.transparentLightBlack};
  padding: 50px 50px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  transition: box-shadow 0.15s ease-in-out;

  ${media.mobile`
    width: 100%;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  `}
`

export const MainTitle = styled.h1`
  font-size: 40px;
  font-family: jamedaregular, sans-serif;
  font-weight: 100;
  color: ${colors.darkGray};

  ${media.mobile`
    font-size: 24px;
  `}
`

export const Button = styled.a`
  display: inline-block;
  background-color: ${colors.secondary};
  width: 200px;
  border: none;
  border-radius: 30px;
  box-shadow: 0 3px 6px ${colors.transparentLightBlack};
  color: ${colors.white};
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  font-size: 17px;
  transition: box-shadow 0.15s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:focus,
  &:hover {
    outline: none;
    box-shadow: 0 5px 10px ${colors.transparentLightBlack};
  }
`
