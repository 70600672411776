import React, { useContext } from 'react'
import { Button, FormWrapper, FormInnerWrapper, MainTitle } from '../ui/forms'
import HeartContext from '../HeartContext'
import delimitNumbers from '../../utils/delimitNumbers'

const ThankYouForm = () => {
  const [{ totalCount }] = useContext(HeartContext)
  return (
    <FormWrapper>
      <FormInnerWrapper>
        <MainTitle>
          DANKE für Ihren Einsatz während der Corona-Pandemie!
        </MainTitle>
        <p>
          Einfach mal Danke sagen: In den letzten Monaten konnten Sie Ihrem
          Arzt, Ihrer Hebamme, Klinik oder Apotheke ein Herz der Dankbarkeit
          schicken! Für jedes 10. Herz haben wir 1€ gespendet.
        </p>
        <p>
          Unser Ziel: Eine Deutschlandkarte voller Herzen! Und dazu ein prall
          gefüllter Spendentopf! Dank Ihrer Hilfe haben wir{' '}
          {delimitNumbers(totalCount)} Herzen gesammelt und{' '}
          {delimitNumbers(Math.round(totalCount / 10))}€ gespendet.
        </p>
        <p>
          <strong>
            Weil auch wir als jameda Danke sagen möchten, erhöhen wir den
            Spendenbetrag auf 20.000€!
          </strong>
        </p>
        <p>
          Wir danken allen Ärzten, Hebammen, Pflegekräften, Kliniken und
          Apotheken für ihren unermüdlichen Einsatz im Kampf gegen die
          Corona-Pandemie.
        </p>
        <p>
          <Button href="https://www.jameda.de/">Arzt finden & buchen</Button>
        </p>
      </FormInnerWrapper>
    </FormWrapper>
  )
}

export default ThankYouForm
