import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ThankYouForm from '../components/ThankYouForm'

const Home = () => (
  <Layout>
    <SEO />
    <ThankYouForm />
  </Layout>
)

export default Home
